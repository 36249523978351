import React from 'react';
import { Link } from 'gatsby';

interface Props {
  data: FooterProps;
}
const Footer = ({ data: { logo, copyright, privacy } }: Props) => (
  <footer className="footer">
    <div className="container">
      <div className="footer-logo">
        <Link to="/">
          <img src={logo.file} alt={logo.text} />
        </Link>
      </div>
      <span className="copyright">&copy; {copyright}</span>
      <div className="credits">
        <Link to="/privacy">
          {privacy}
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;

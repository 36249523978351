import React, { Component } from 'react';
import { Link } from 'gatsby';
import { withCookies, Cookies } from 'react-cookie';

interface Props {
  cookies?: Cookies;
  gdpr_text: string;
}
interface State {
  closed: boolean;
}
class Gdpr extends Component<Props, State> {
  state = {
    closed: false
  };
  componentDidMount = () => {
    if (this.props.cookies && this.props.cookies.get('GDPRClosed')) {
      this.setState({
        closed: true
      });
    }
  };
  onClose = () => {
    if (this.props.cookies) {
      this.props.cookies.set('GDPRClosed', true);
    }
    this.setState({
      closed: true
    });
  };
  render() {
    return this.state.closed ? null : (
      <div className="gdpr">
        <div>
          {this.props.gdpr_text} &nbsp;
          <Link to="/privacy">Learn more</Link>
          <i className="fa fa-close" onClick={this.onClose} />
        </div>
      </div>
    );
  }
}
export default withCookies(Gdpr);

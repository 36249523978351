import React, { Fragment, ReactNode } from 'react';

import SeoSection from './Seo';

import '../style/index.scss';

interface Props {
  children: ReactNode;
}
const Layout = ({ children }: Props) => (
  <Fragment>
    <SeoSection />
    {children}
  </Fragment>
);

export default Layout;

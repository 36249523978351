import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import ReactGA from 'react-ga';

import { formatMarkdownData } from '../data/_helper';
import Gdpr from './Gdpr';

const GoogleAnalytics = (ga: string) => {
  if (ga) {
    ReactGA.initialize(`${ga}`);
  }
};

interface Meta {
  name: string;
  content: string;
}
interface SeoTemplateProps {
  title: string;
  description?: string;
  lang?: string;
  author?: string;
  meta?: Meta;
  keywords?: string[];
  gdpr_text: string;
  ga: string;
}
const SeoTemplate = ({ description, lang, keywords, title, author, gdpr_text, ga }: SeoTemplateProps) => (
  <Fragment>
    {GoogleAnalytics(ga)}
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(
        keywords && keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
          : []
      )}
    />
    <Gdpr gdpr_text={gdpr_text} />
  </Fragment>
);

interface SeoSectionProps {
  pageTitle?: string;
}
const SeoSection = ({ pageTitle }: SeoSectionProps) => (
  <StaticQuery
    query={graphql`
      query SeoQuery {
        allMarkdownRemark(limit: 1, filter: { frontmatter: { section: { eq: "seo" } } }) {
          edges {
            node {
              id
              frontmatter {
                lang
                description
                keywords
                author
                title
                gdpr_text
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { title, description, lang, keywords, author, gdpr_text, ga } = formatMarkdownData<SeoTemplateProps>(
        data
      )[0];
      return (
        <SeoTemplate
          title={pageTitle || title}
          description={description}
          lang={lang}
          keywords={keywords}
          author={author}
          gdpr_text={gdpr_text}
          ga={ga}
        />
      );
    }}
  />
);

export default SeoSection;

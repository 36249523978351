interface Node {
  node: {
    id: string;
    frontmatter: any;
  };
}
interface Props {
  allMarkdownRemark: {
    edges: Node[];
  };
}
export const formatMarkdownData = <T>({ allMarkdownRemark }: Props): T[] => {
  return allMarkdownRemark.edges.map(({ node }) => ({ key: node.id, ...node.frontmatter }));
};
